export default class MetasRepresUploadModel {
  codRepres = '';

  metaQuantidade = '0';

  metaValor = '0';

  constructor(codRepres: string, metaQuantidade: string, metaValor: string) {
    this.codRepres = codRepres;
    this.metaQuantidade = metaQuantidade;
    this.metaValor = metaValor;
  }
}
