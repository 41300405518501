export default class MetasLinhaUploadModel {
  codRepres = '';

  codCli = '';

  codLinha = '';

  metaQuantidade = '0';

  metaValor = '0';

  constructor(codRepres: string, codCli: string, codLinha: string, metaQuantidade: string, metaValor: string) {
    this.codRepres = codRepres;
    this.codCli = codCli;
    this.codLinha = codLinha;
    this.metaQuantidade = metaQuantidade;
    this.metaValor = metaValor;
  }
}
